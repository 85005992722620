import { useContext } from 'react'
import { queryCache, useMutation } from 'react-query'
import {
  trackIssueCreditIssued,
  trackIssueOrderReshipped,
  trackIssueReplacementsAdded,
} from '~/analytics/events'
import { CACHE_KEY_ORDERS, CACHE_KEY_UPCOMING_ORDER } from '~/bb-api/constants'
import { ORDER_ISSUE } from '~/bb-api/endpoints'
import { Order, OrderIssue, Success } from '~/bb-api/schemata'
import { ReportAnIssueContext } from '~/context/reportAnIssue'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import { captureNetworkError } from '~/analytics/errors'
import useUpcomingOrderId from './useUpcomingOrderId'

const mutateOrderIssue = (orderId: Order['id']) => {
  const { id: userId } = useContext(UserContext)
  const { creditAmount } = useContext(ReportAnIssueContext)
  const upcomingOrderId = useUpcomingOrderId()

  const endPoint = ORDER_ISSUE(userId, orderId)
  const performMutateOrderIssue = (orderIssue: OrderIssue) =>
    axios
      .post(endPoint, { ...orderIssue, message: orderIssue.message || null })
      .then((response) => response.data as Success)

  return useMutation(performMutateOrderIssue, {
    onSuccess(_, orderIssue) {
      queryCache.invalidateQueries(CACHE_KEY_ORDERS)
      queryCache.invalidateQueries(CACHE_KEY_UPCOMING_ORDER)

      switch (orderIssue.resolution) {
        case 'credit':
          trackIssueCreditIssued(orderIssue, orderId, creditAmount)
          break
        case 'items':
          trackIssueReplacementsAdded(orderIssue, orderId, upcomingOrderId)
          break
        case 'reship':
          trackIssueOrderReshipped(orderIssue, orderId)
          break
      }
    },
    onError(e: any) {
      captureNetworkError(e)
    },
  })
}

export default mutateOrderIssue
