import { styled, rem } from '~/design'

export const CardGrid = styled.div`
  display: grid;
  gap: ${rem(8)};
  grid-template-columns: 1fr;
  max-width: ${rem(488)};
  padding-bottom: ${rem(24)};
  ${(p) => p.theme.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const CtaWrap = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: ${rem(80)};
  width: 100%;
  ${(p) => p.theme.media.tablet} {
    width: ${rem(256)};
  }
`
