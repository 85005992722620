import { Box, Flex, Grid } from '@chakra-ui/core'
import { rem } from 'design'
import CardProduct from 'design/components/CardProduct/CardProduct'
import Form from 'design/components/Form/Form'
import ModalCardRadio, {
  IModalCardRadio,
} from 'design/components/ModalCardRadio/ModalCardRadio'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import Tooltip from 'design/components/Tooltip/Tooltip'
import { Body } from 'design/components/Typography/Typography'
import { Field, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { OrderIssueResolution } from '~/bb-api/schemata'
import { IReportAnIssueFlowRoutingProps } from '~/components/ReportAnIssueModals/constants'
import { ReportAnIssueContext } from '~/context/reportAnIssue'
import mutateOrderIssue from '~/hooks/mutateOrderIssue'
import { formatPriceFromCents } from '~/utils'
import { captureNetworkError } from '~/analytics/errors'
import { TEST_ID } from '~/constants/cypress'
import { Modal, ModalBody } from '@butcherbox/freezer'
import ReportAnIssueFooter from './ReportAnIssueFooter'
import IconInfoBubble from 'design/components/Icons/defs/InfoBubble'

const FORM_ID = 'form-how-can-we-fix-this'

type IPanelHowCanWeFixThis = {
  handleModalClose: () => void
} & IReportAnIssueFlowRoutingProps

const RadioCardHowCanWeFixThis = ModalCardRadio as React.FC<
  {
    value: OrderIssueResolution
  } & IModalCardRadio
>

const orderIssueResolutions: OrderIssueResolution[] = ['credit', 'items']

const HowCanWeFixThisSchema = yup
  .object()
  .required()
  .shape({
    issueResolution: yup.string().required().oneOf(orderIssueResolutions),
  })

const tooltipText =
  'A box with fewer than 4 items has a high chance of defrosting in transit, so they must be shipped with your next order.'

const PanelHowCanWeFixThis: React.FC<IPanelHowCanWeFixThis> = ({
  handleModalClose,
}) => {
  const {
    orderIssue,
    affectedOrder,
    creditAmount,
    orderItemsBySku,
  } = React.useContext(ReportAnIssueContext)
  const showToast = React.useContext(ToastContext)
  const [mutate] = mutateOrderIssue(affectedOrder.id)

  const initialValue: OrderIssueResolution = 'items'
  const formattedCreditAmount = formatPriceFromCents(creditAmount)

  return (
    <Modal onClose={handleModalClose} size="m" title="How can we fix this?">
      <Formik
        initialValues={{ issueResolution: initialValue }}
        onSubmit={({
          issueResolution,
        }: {
          issueResolution: OrderIssueResolution
        }) => {
          const toastText =
            issueResolution === 'credit'
              ? `Success! ${formattedCreditAmount} credit added to your account.`
              : 'Success! Your replacement items have been added to your next box.'

          return mutate(
            { ...orderIssue, resolution: issueResolution },
            {
              onSuccess: () =>
                showToast('success', {
                  children: toastText,
                }),
              onError: (e: any) => {
                captureNetworkError(e, { issueResolution })
                showToast('error', {
                  children:
                    'We encountered an issue. Please contact customer support.',
                })
              },
              onSettled: () => {
                handleModalClose()
              },
            }
          )
        }}
        validateOnMount={true}
        validationSchema={HowCanWeFixThisSchema}
      >
        {({ isSubmitting, handleSubmit, isValid }) => {
          return (
            <>
              <ModalBody>
                <Form
                  data-cy={TEST_ID.MODAL_HOW_CAN_WE_FIX_THIS}
                  id={FORM_ID}
                  mx={{ base: rem(-32), tablet: '0' }}
                  onSubmit={handleSubmit}
                  px={{ base: rem(8), tablet: rem(100) }}
                  title="Choose an order issue resolution form"
                >
                  <Body
                    pb={rem(17)}
                    pt={rem(14)}
                    px={rem(8)}
                    textAlign={{ base: 'left', tablet: 'center' }}
                    width="100%"
                  >
                    Our customer service team will look into the issues with
                    your delivery. We can add a credit to your account or add
                    the following cuts to your next box. <BoxThawingTooltip />
                  </Body>
                  <Grid
                    gridTemplateColumns={{
                      base: '1fr',
                      tablet: 'repeat(2, 1fr)',
                    }}
                    maxWidth={rem(488)}
                    mb={rem(24)}
                    mx={{ base: rem(8), tablet: 'auto' }}
                  >
                    {orderIssue.items.map((sku, idx) => {
                      return (
                        <CardProduct
                          key={idx}
                          mb={rem(6)}
                          product={orderItemsBySku[sku]}
                        />
                      )
                    })}
                  </Grid>
                  <Flex
                    alignItems="center"
                    as="fieldset"
                    flexDirection="column"
                    justifyContent="flex-start"
                    mb={rem(25)}
                  >
                    <Field
                      component={RadioCardHowCanWeFixThis}
                      data-cy={TEST_ID.MODAL_OPTION}
                      fieldName="issueResolution"
                      labelProps={{ mb: rem(16) }}
                      name="issueResolution"
                      size="sm"
                      type="radio"
                      value="items"
                    >
                      <Body>Add missing cuts to my next box</Body>
                    </Field>
                    <Field
                      component={RadioCardHowCanWeFixThis}
                      data-cy={TEST_ID.MODAL_OPTION}
                      fieldName="issueResolution"
                      labelProps={{ mb: rem(16) }}
                      name="issueResolution"
                      size="sm"
                      type="radio"
                      value="credit"
                    >
                      <Body>{`Apply ${formattedCreditAmount} credit to my next box`}</Body>
                    </Field>
                  </Flex>
                </Form>
              </ModalBody>

              <ReportAnIssueFooter
                buttonText="Submit"
                formId={FORM_ID}
                isDisabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
              />
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

export const BoxThawingTooltip = () => {
  return (
    <Tooltip content={tooltipText} position="bottom">
      <Box display="inline-block">
        <IconInfoBubble
          color="bb.spicedCrimson"
          transform={`translateY(${rem(2)})`}
          verticalAlign="baseline"
        />
      </Box>
    </Tooltip>
  )
}

export default PanelHowCanWeFixThis
