import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import { OrderIssueResolution } from '~/bb-api/schemata'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { ReportAnIssueContext } from '~/context/reportAnIssue'
import mutateOrderIssue from '~/hooks/mutateOrderIssue'
import mutateSubscriptionIssue from '~/hooks/mutateSubscriptionIssue'
import { formatPriceFromCents } from '~/utils'
import { captureNetworkError } from '~/analytics/errors'
import PanelHowCanWeFixThisUI from './PanelHowCanWeFixThis.ui'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

/**
 * This panel presents a user with options to add effected cuts to
 * their next box, receive a credit, or stop their subscription.
 */
const PanelHowCanWeFixThis: React.FC<CancelPanelProps> = ({
  handleModalClose,
  panel,
}) => {
  const {
    orderIssue,
    affectedOrder,
    creditAmount,
    orderItemsBySku,
  } = React.useContext(ReportAnIssueContext)
  const showToast = React.useContext(ToastContext)
  const [mutate] = mutateOrderIssue(affectedOrder.id)
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)
  const [mutateSubIssue] = mutateSubscriptionIssue()

  const formattedCreditAmount = formatPriceFromCents(creditAmount)

  const handleClick = React.useCallback(
    (issueResolution: OrderIssueResolution) => {
      const { toastText, saveAction } = ((
        resolution
      ): {
        toastText: string
        saveAction:
          | CANCEL_SAVE_ACTIONS.APPLY_CREDIT
          | CANCEL_SAVE_ACTIONS.ADD_FREE_EXTRAS
      } => {
        switch (resolution) {
          case 'credit':
            return {
              toastText: `Success! ${formattedCreditAmount} credit added to your account.`,
              saveAction: CANCEL_SAVE_ACTIONS.APPLY_CREDIT,
            }
          case 'items':
          default:
            return {
              toastText:
                'Success! Your replacement items have been added to your next box.',
              saveAction: CANCEL_SAVE_ACTIONS.ADD_FREE_EXTRAS,
            }
        }
      })(issueResolution)

      return mutate(
        { ...orderIssue, resolution: issueResolution },
        {
          onSuccess: () => {
            showToast('success', {
              children: toastText,
            })
            trackCancellationSave(
              saveAction,
              getHistoryEntry(1).pathname,
              getHistoryEntry(2).state.text
            )
            mutateSubIssue({
              issueType: orderIssue.issueType ?? 'other',
              message: orderIssue.message ?? null,
            })
          },
          onError: (e: any) => {
            captureNetworkError(e, { issueResolution })
            showToast('error', {
              children:
                'We encountered an issue. Please contact customer support.',
            })
          },
          onSettled: () => {
            handleModalClose()
          },
        }
      )
    },
    [
      mutate,
      orderIssue,
      formattedCreditAmount,
      showToast,
      getHistoryEntry,
      mutateSubIssue,
      handleModalClose,
    ]
  )

  return (
    <PanelHowCanWeFixThisUI
      formattedCreditAmount={formattedCreditAmount}
      handleClick={handleClick}
      orderIssue={orderIssue}
      orderItemsBySku={orderItemsBySku}
      panel={panel}
      showCredit={creditAmount > 0}
    />
  )
}

export default PanelHowCanWeFixThis
