import { useContext } from 'react'
import { useMutation } from 'react-query'
import { SUBSCRIPTION_ISSUE } from '~/bb-api/endpoints'
import { SubscriptionIssue, Success } from '~/bb-api/schemata'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import { captureNetworkError } from '~/analytics/errors'

const mutateSubscriptionIssue = () => {
  const { id: userId } = useContext(UserContext)
  const { subscription } = useContext(SubscriptionContext)

  const endPoint = SUBSCRIPTION_ISSUE(userId, subscription.id)
  const performMutateSubscriptionIssue = (
    subscriptionIssue: SubscriptionIssue
  ) =>
    axios
      .post(endPoint, { ...subscriptionIssue })
      .then((response) => response.data as Success)

  return useMutation(performMutateSubscriptionIssue, {
    onError(e: any) {
      captureNetworkError(e)
    },
  })
}

export default mutateSubscriptionIssue
