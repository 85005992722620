import React from 'react'
import { SubscriptionContext } from '~/context/subscription'

export default function useUpcomingOrderId() {
  React.useDebugValue('useUpcomingOrderId')

  const { subscription } = React.useContext(SubscriptionContext)

  return `${subscription.id}-${subscription.month + 1}`
}
