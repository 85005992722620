import { Flex } from '@chakra-ui/core'
import { rem } from 'design'
import CardProduct from 'design/components/CardProduct/CardProduct'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import { BoxThawingTooltip } from '~/components/ReportAnIssueModals/PanelHowCanWeFixThis'
import { TEST_ID } from '~/constants/cypress'
import StopMySubscriptionButton from '../../StopMySubscriptionButton/StopMySubscriptionButton'
import * as Styled from './PanelHowCanWeFixThis.style'
import type * as TYPES from './PanelHowCanWeFixThis.types'
const PanelHowCanWeFixThisUI: React.FC<TYPES.PanelHowCanWeFixThisUIProps> = ({
  panel,
  showCredit,
  formattedCreditAmount,
  orderIssue,
  orderItemsBySku,
  handleClick,
}) => {
  const credit_subhead = showCredit
    ? `We can apply a ${formattedCreditAmount} credit or add the following cuts to your next box.`
    : ''

  return (
    <PanelLayout>
      <Flex alignItems="flex-start" flexDirection="column">
        <div>
          <ModalHeader pb={rem(8)} variant="bare">
            {panel.headline}
          </ModalHeader>
          <Body pb={rem(12)} textAlign="left" width="100%">
            {panel.subhead}
            {credit_subhead}
            <BoxThawingTooltip />
          </Body>
        </div>
        <Styled.CardGrid>
          {orderIssue.items.map((sku, idx) => (
            <CardProduct key={idx} product={orderItemsBySku[sku]} />
          ))}
        </Styled.CardGrid>
        <Styled.CtaWrap>
          <ModalItemSmall
            data-cy={TEST_ID.CANCEL_FLOW_SAVE}
            height={rem(56)}
            mb={rem(16)}
            onClick={() => handleClick('items')}
          >
            Add cuts to my next box
          </ModalItemSmall>
          {showCredit && (
            <ModalItemSmall
              data-cy={TEST_ID.CANCEL_FLOW_SAVE}
              mb={rem(16)}
              onClick={() => handleClick('credit')}
            >
              {`Apply ${formattedCreditAmount} credit`}
            </ModalItemSmall>
          )}
          <StopMySubscriptionButton />
        </Styled.CtaWrap>
      </Flex>
    </PanelLayout>
  )
}

export default PanelHowCanWeFixThisUI
